<template>
  <div class="main-container">
    <div>
      <h5 class="pageTitle">비밀번호 변경</h5>
    </div>
    <mdb-container class="form-container">
      <form>
        <p>새 비밀번호</p>
        <mdb-input type="password" label="새 비밀번호 입력" outline class="m-1" v-model="changePassword"/>
        <br/>
        <p>새 비밀번호 확인</p>
        <mdb-input type="password" label="새 비밀번호 확인 입력" outline class="m-1" v-model="reChangePassword"/>
      </form>
    </mdb-container>
    <mdb-container class="text-right">
      <mdb-btn outline="primary" @click="putPassword">수정</mdb-btn>
    </mdb-container>
  </div>

</template>

<script>
import {mdbBtn, mdbContainer, mdbInput,} from "mdbvue";
import axios from "@/axios";

export default {
  name: "ChangePassword",
  components: {
    mdbContainer,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      token: this.$store.state.token.access_token,
      changePassword: '',
      reChangePassword: '',
    }
  },
  methods: {
    putPassword() {
      const self = this;
      if (self.changePassword.trim().length * self.reChangePassword.trim().length === 0) {
        self.$swal.fire({title: '입력 필수', text: '항목을 입력해 주세요.', icon: 'info'})
        return
      }

      if (self.changePassword !== self.reChangePassword) {
        self.$swal.fire({title: '입력 오류', text: '패스워드가 일치하지 않습니다.', icon: 'error'})
        return
      }

      const data = JSON.stringify({
        "password": self.changePassword
      });

      const config = {
        method: 'put',
        url: '/admins/password',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`
        },
        data: data
      };

      axios(config)
          .then(() => {
            self.$swal.fire({title: '수정 완료', text: '비밀번호가 변경 되었습니다.', icon: 'success'})
                .then((result) => {
                  if (result.isConfirmed) {
                    self.changePassword = ''
                    self.reChangePassword = ''
                  }
                })
          })

    }
  }
}
</script>

<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
}

.form-container {
  margin-top: 30px;
}
</style>
